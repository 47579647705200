import React, { useState } from "react"
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  SxProps,
  TextField,
  Typography, 
} from "@mui/material"
import { 
  DisplayPicture,
  LoadingButton,
  LoadingLinear, 
  Styled, 
  useCalendarEventRSVPs, 
  useCalendarEvents, 
  useChatRooms,
  useEnduserSession, 
  useFormResponses, 
  useResolvedSession, 
  useUsers, 
  value_is_loaded 
} from "@tellescope/react-components"
import { Link } from "../components/Link"
import { routes } from "../definitions/routes"
import { dashboardChildStyles } from "../definitions/constants"
import { ResponsiveModal } from "../components/layout"
import { TogglePasswordIcon } from "../components/inputs"
import { password as passwordValidation } from "../definitions/schemas"
import { AlertIcon, InfoIcon, SecurityIcon } from "../components/icons"
import { WithCircularBackground } from "../definitions/icons"
import { user_display_name } from "@tellescope/utilities"
import { User } from "@tellescope/types-client"
import isEmail from "validator/lib/isEmail"
import { phoneValidator } from "@tellescope/validation"

const useUnsubmittedFormCount = () => {
  const [formResponsesLoading] = useFormResponses()
  if (!value_is_loaded(formResponsesLoading)) return undefined

  const unsubmittedCount = (
    formResponsesLoading 
    .value
    .filter(f => 
      !f.hideFromEnduserPortal
      && !f.submittedAt
      && !f.isInternalNote
      && !f.draftSavedAt // enduser can't currently save draft
    )
    .length
  )
  return unsubmittedCount
}
export const UnsubmittedFormsAlert = ({ style } : Styled) => {
  const unsubmittedCount = useUnsubmittedFormCount()
  if (!unsubmittedCount) return null
  return (
    <Paper elevation={5} style={{ ...style, borderRadius: 10 }}>
    <Alert severity="warning" icon={<AlertIcon />}>
      You have {unsubmittedCount} outstanding form{unsubmittedCount > 1 ? 's' : ''} - <Link to={routes.documents}>click here to view</Link>
    </Alert>
    </Paper>
  )
}

const useUnreadMessageCount = () => {
  const session = useEnduserSession()
  const [chatRooms] = useChatRooms()
  if (!value_is_loaded(chatRooms)) return undefined

  const unreadCount = (
    chatRooms
    .value
    .map(c => c.infoForUser?.[session.userInfo.id]?.unreadCount ?? 0)
    .reduce((a, b) => a + b, 0)
  )

  return unreadCount
}

export const UnreadMessagesAlert = ({ style } : Styled) => {
  const unreadCount = useUnreadMessageCount()
  if (!unreadCount) return null
  return (
    <Paper elevation={5} style={{ ...style, borderRadius: 10 }}>
    <Alert severity="info" icon={<InfoIcon />}>
      You have {unreadCount} unread message{unreadCount > 1 ? 's' : ''} - <Link to={routes.communications}>click here to view</Link>
    </Alert>
    </Paper>
  )
}

export const Alerts = ({ sx } : { sx?: SxProps }) => {
  const unreadCount = useUnreadMessageCount()
  const unsubmittedCount = useUnsubmittedFormCount()

  if (!(unreadCount || unsubmittedCount)) return null

  return (
    <Grid container direction="column" sx={sx}>
      <UnsubmittedFormsAlert style={{ marginBottom: 8 }} />
      <UnreadMessagesAlert style={{ marginBottom: 8 }} />
    </Grid>
  )
}

const providerCardTitleStyle: React.CSSProperties = {
  fontSize: 20,
  fontWeight: 'bold',
  opacity: 0.8,
}
const providerCardItemStyle: React.CSSProperties = { 
  fontSize: 16.5,
  fontWeight: 'bold',
  opacity: 0.7,
}
export const ProviderCardItem = ({
  title, 
  broken,
  entry, 
  href,
} : {
  title: string,
  entry: string | React.ReactNode,
  broken?: boolean,
  href?: string,
}) => (
  <Grid container>
    <Grid item xs={broken ? 12 : 6} sx={{ minWidth: 200 }}>
      <Typography style={providerCardTitleStyle}>{title}</Typography>
    </Grid>

    <Grid item xs={broken ? 12 : 6} sx={{ minWidth: 200, maxHeight: 210, overflowY: 'auto' }}>
    {typeof entry === 'string'
      ? (
        <Typography style={providerCardItemStyle}>
        {href
          ? <a href={href} style={{ color: 'black' }}>{entry}</a>
          : entry
        }
        </Typography>
      )
      : entry
    }
    </Grid>
  </Grid>
)

const UserDisplay = ({ user, email, phone } : { user: User, phone?: string, email?: string }) => (
  <Paper elevation={0} sx={{ p: 2.5, border: '1px solid black' }}>
    <Grid container direction={"column"} spacing={2}>
      <Grid item>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography sx={{ fontSize: 21, fontWeight: 'bold' }}>
            {user_display_name(user)}
          </Typography>
        </Grid>

        <Grid item>
          <DisplayPicture user={user} size={40} />
        </Grid>
      </Grid>
      </Grid>

      <Grid item>
        <Divider sx={{ borderColor: 'secondary.main', borderWidth: 2.5 }} flexItem />
      </Grid>

      {email &&
        <Grid item>
          <ProviderCardItem title="Email" entry={email} href={`mailto:${email}`} />
        </Grid>
      }
      {phone &&
        <Grid item>
          <ProviderCardItem title="Phone" entry={phone} href={`tel:${phone}`} />
        </Grid>
      }

      <Grid item>
        <ProviderCardItem title="Bio" entry={user.bio ?? ''} broken />
      </Grid>

      <Grid item>
        <ProviderCardItem title="Specialties" entry={user.specialties?.join(', ') ?? ''} />
      </Grid>
      

      {/* <Grid item>
        <ProviderCardItem title="Licenses" 
          entry={
            <Grid container direction="column">
            {user.credentialedStates?.map(c => (
              <Grid item key={c.state}>
                <Typography style={providerCardItemStyle}>
                  {c.state}{c.licenseId ? `: ${c.licenseId}` : ""}
                </Typography>
              </Grid>
            ))}
            </Grid>
          } 
        />
      </Grid> */}
    </Grid>
    </Paper>        
)

const email_from_tags = (tags=[] as string[]) => tags.find(t => isEmail(t))
const phone_from_tags = (tags=[] as string[]) => tags.find(t => {
  try {
    phoneValidator.validate()(t)
    return true
  } catch(err) {}

  return false
})

const sectionTitleStyle: React.CSSProperties = {
  fontSize: 23, 
  fontWeight: 'bold'
}
export const MyProviders = () => {
  const [, { findById: findUser }] = useUsers()
  const session = useEnduserSession()

  const assignedTo = Array.from(new Set(session.userInfo.assignedTo ?? []))
  const users = (
    assignedTo
    .map(userId => (findUser(userId, { batch: assignedTo.length > 1 }))!)
    .filter(u => u) // ensure non-null/undefined
    .reverse() // from newly assigned [0] to old
  )
  const providers = users.filter(u => u?.tags?.includes("Provider"))
  const managers = users.filter(u => u?.tags?.includes("Case Manager"))

  if (providers.length === 0 && managers.length === 0) {
    return (
      <Typography>
        When your care team is assigned, they will appear here
      </Typography>
    )
  }

  const pairs: [User | undefined, User | undefined][] = []
  for (let i=0; i < Math.max(providers.length, managers.length); i++) {
    pairs.push([providers[i], managers[i]])
    break // only show 1 pair for now, dec, 27, 2024
  }

  return (
    <Grid container direction={"column"} spacing={1}>
      <Grid item sx={{ height: 35 }}>
      <Grid container wrap="nowrap">
        <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'flex' }}}>
        {providers.length > 0 && 
          <Grid item>
            <Typography style={sectionTitleStyle}>
              Your Provider
              {/* {providers.length > 1 ? 's' : ''} */}
            </Typography>
          </Grid>
        }
        </Grid>
        
        <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'flex' }}}>
        {managers.length > 0 && 
          <Grid item>
            <Typography style={sectionTitleStyle}>
              Your Health Coach
              {/* {managers.length > 1 ? 's' : ''} */}
            </Typography>
          </Grid>
        }
        </Grid> 

        <Grid item xs={12} sx={{ display: { sm: 'none' }}}>
          <Typography style={sectionTitleStyle}>
            Your Team
          </Typography>
        </Grid>
      </Grid>
      </Grid>

      {pairs.map(([provider, manager], i) => (
        <Grid item key={i}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            {provider && <UserDisplay user={provider} />}
          </Grid>
        
          <Grid item xs={12} sm={6}>
            {manager && 
              <UserDisplay user={manager}
                phone={phone_from_tags(manager.tags)}
                email={email_from_tags(manager.tags)}
              />
            }
          </Grid>
        </Grid>
        </Grid>
      ))}
    </Grid>
  ) 
}

export const DefaultHome = () => {
  const session = useResolvedSession()

  const [eventsLoading] = useCalendarEvents()
  const [, { filtered }] = useCalendarEventRSVPs({ loadFilter: { creator: session.userInfo.id } })
  
  const creatorRSVPsLoading = filtered(r => r.creator === session.userInfo.id)

  return (
    <LoadingLinear data={eventsLoading} render={events => (
      <Grid container direction="column" style={{ ...dashboardChildStyles }}>
        {session.userInfo.fname && 
          <Grid item sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 35 }}>
              Hello, {session.userInfo.fname}!
            </Typography>
          </Grid>
        }

        <UnsubmittedFormsAlert style={{ marginBottom: 20 }} />
        <UnreadMessagesAlert style={{ marginBottom: 20 }} />

        {/* <EventsTable events={
          events.filter(e => (
            e.publicRead // only display public events when the client is RSVPed
              ? value_is_loaded(creatorRSVPsLoading)
                ? creatorRSVPsLoading.value.find(rsvp => rsvp.eventId === e.id)
                : false
              : true
          ))
        } /> */}

        <Grid item sx={{ mt: 2 }}>
          <MyProviders />
        </Grid>

        {/* <Grid item>
          <Grid container alignItems="center">
            <Typography style={sectionTitleStyle}>
              Products
            </Typography>

            <ViewCartIconButton />
          </Grid>

          <ProductsLoading />
        </Grid> */}
      </Grid> 
    )} />
  )
}

export const Home = () => (
  <DefaultHome />
)

const password_validation = (password: string): string | undefined => {
  try {
    // throws error if invalid
    passwordValidation.validateSync(password)
    return 
  } catch(err: any) {
    return err?.message
  }
}

const HIDE_PROMPT = 'hide_set_password_prompt'
export const WithSetInitialPasswordPrompt = ({ children } : { children: React.ReactNode }) => {
  const session = useEnduserSession()
  const [password, setPassword] = useState('')
  const [hide, setHide] = useState(!!window.localStorage[HIDE_PROMPT] || session.userInfo.passwordIsUnset !== true)
  const [showPassword, setShowPassword] = useState(false)

  if (hide) return <>{children}</>

  const passwordValidationMessage = password_validation(password)

  const handleHide = () => {
    window.localStorage[HIDE_PROMPT] = new Date();
    setHide(true)
  }

  return (
    <>
    <ResponsiveModal open={true} setOpen={() => undefined} maxWidth={600}>
    <Grid container justifyContent="center">
    <Grid container style={{ width: '100%', maxWidth: 500 }}>
    <Grid container direction="column" justifyContent="center" spacing={2}
      sx={{ minHeight: 500, mb: 6, mt: 4 }}
    >
      <Grid item alignSelf="center" sx={{ width: 60, height: 50, mb: 1 }}>
      <WithCircularBackground >
        <SecurityIcon style={{ width: 30, height: 30 }} />
      </WithCircularBackground>
      </Grid>

      <Grid item alignSelf="center" sx={{ my: 1 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', maxWidth: 300 }}>
          Set a password for future logins.
        </Typography>
      </Grid>

      <Grid item alignSelf="center" sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 17, opacity: 0.55, textAlign: "center", lineHeight: '35px' }}>
          At least 8 characters. <br/>
          A mixture of uppercase and lowercase.<br/>
          A mixture of letters and numbers.<br/>
        </Typography>
      </Grid>


      <Grid item>
        <TextField 
          label="Password" variant="outlined" fullWidth autoFocus
          value={password} onChange={e => setPassword(e.target.value)}
          style={{ borderRadius: 15 }}
          sx={{
            [`& fieldset`]: {
              borderRadius: 15,
            },
            "& .MuiInputBase-input": {
              marginLeft: 1,
            },
          }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{ 
            endAdornment: <TogglePasswordIcon showing={showPassword} onChange={setShowPassword} />
          }}
        />
      </Grid>

      <Grid item>
        <LoadingButton variant="contained" submitText="Save" disabled={!!passwordValidationMessage}     
          onClick={async () => {
            await session.api.endusers.set_password({ id: session.userInfo.id, password })
            handleHide()
          }}
        />
        {password && passwordValidationMessage &&
          <Typography color="error" sx={{ mt: 0.5 }}>
            {passwordValidationMessage} 
          </Typography>
        }
      </Grid>

      <Grid item>
        <Button variant="outlined" fullWidth onClick={handleHide}> 
          No Thanks
        </Button>
      </Grid>
    </Grid>
    </Grid>
    </Grid>
    </ResponsiveModal>
    
    {children}
    </>
  )
}